import React, { useState } from "react";
import "./style.css";

import { Link, useNavigate } from "react-router-dom";
import Screenshot from "../static/img/medicine.webp";
import ImageLogo from "../static/img/GTExtended_RGB.svg";
import HeartCardiogram from "../static/img/heart_cardiogram.svg";
import { postJSON } from "../utils/utils";
import { useSessionUser } from "../SessionUserProvider";

export const LoginPage = (): JSX.Element => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [rememberMe, setRememberMe] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const { setSessionUser } = useSessionUser();
  const navigate = useNavigate();

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const handleRememberMeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRememberMe(event.target.checked);
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleLogin();
    }
  };

  const handleLogin = async () => {
    let isError = false;

    if (email === "") {
      setErrorMessage("Please enter your email");
      isError = true;
    } else if (password === "") {
      setErrorMessage("Please enter your password");
      isError = true;
    }

    setError(isError);

    if (isError) {
      return;
    }

    const data = {
        email: email,
        password: password,
        rememberMe: (rememberMe) ? "1" : ""
    };

    const responseData = await postJSON("/api/login", data);
  
    if ("error" in responseData) {
        setErrorMessage(responseData["error"]);
        isError = true;
    } else {
        setSessionUser(responseData);
        navigate("/");
    }

    setError(isError);
  };

  return (
    <>
    <div className="login-page">

      <div className="login-input-wrapper">
        <div className="heart-rate-icon">
          <img width="190" height="190" alt="Heart icon" src={HeartCardiogram} />
        </div>

        <div className="welcome-text">Welcome Back!</div>

        <div>
          <div className="input-label">Email:</div>
          <input type="text" className="text-input" aria-label="Email" value={email} onChange={handleEmailChange} onKeyDown={handleKeyDown} />
        </div>

        <div>
          <div className="input-label">Password:</div>
          <input type="password" className="text-input" aria-label="Password" value={password} onChange={handlePasswordChange} onKeyDown={handleKeyDown} />
        </div>

        {error && (
          <div className="error-message">{errorMessage}</div>
        )}

        <div className="options-wrapper">
          <div className="remember-me-wrapper">
            <input type="checkbox" className="remember-me-checkbox" id="remember-me-checkbox" checked={rememberMe} onChange={handleRememberMeChange} />
            <label htmlFor="remember-me-checkbox" className="remember-me-label">Remember me</label>
          </div>
        </div>

        <button className="primary-button login-button" onClick={handleLogin}>Login</button>

        <p className="no-account-wrapper">
          <span>Don't have an account?</span>
          <Link to="/signup" className="login-link">Register</Link>
        </p>
    </div>

      <div className="blue-background">
        <img width="600" height="509" className="screenshot" alt="Screenshot" src={Screenshot} />
        <img width="186" height="42" className="georgia-tech-logo-header" alt="Georgia tech logo" src={ImageLogo} />
      </div>

    </div>
    </>
  );
};
