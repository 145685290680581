import React from "react";
import "./style.css";

import ImageLogo from "../static/img/GTExtended_RGB.svg";

export const Footer = (): JSX.Element => {
  return (
    <img width="186" height="42" className="georgia-tech-logo" alt="Georgia tech logo" src={ImageLogo} />
  );
};
