import React, { useState } from "react";
import "./style.css";

import { Menu } from "../Menu";
import { Header } from "../Header";
import { Footer } from "../Footer";
import SelectPatient from "./SelectPatient";
import ShowDVTRisk from "./ShowDVTRisk";
import AskAi from "./AskAi";

export const DeepVeinThrombosis = (): JSX.Element => {
  const [selectedPatient, setSelectedPatient] = useState<any>(null);
  const [calculatorData, setCalculatorData] = useState<any>({
    active_cancer: 0,
    bedridden_or_surgery: 0,
    calf_swelling: 0,
    superficial_veins: 0,
    leg_swollen: 0,
    localized_dv_tenderness: 0,
    pitting_edema: 0,
    extremity_immobilization: 0,
    previous_dvt: 0,
    alternative_diagnosis: 0,
  });
  const [defaultCalculatorData, setDefaultCalculatorData] = useState(calculatorData);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [risk, setRisk] = useState<number>(0);
  const [scores, setScores] = useState<any>({});
  const [fetchingPatient, setFetchingPatient] = useState<boolean>(false);

  const setUpCalculatorData = (data: any) => {
    setCalculatorData(data);
    setDefaultCalculatorData(data);
  };

  const setupPatientData = (data: any) => {
    setSelectedPatient(data);
    setLoaded(false);
  };

  const handleCalculatorChange = (key: string, value: any) => {
    setCalculatorData({
      ...calculatorData,
      [key]: value
    });
  };

  const checkIfChecked = (key: string, value: any) => {
    return calculatorData[key] === value;
  };

  const resetCalculator = () => {
    setCalculatorData(defaultCalculatorData);
  };

  const calculateRisk = async () => {
    setLoaded(false);
    try {
      const responseRisk = await fetch('/api/calculate_risks/3', {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...calculatorData,
          ...selectedPatient
        })
      });
      if (responseRisk.ok) {
        const dataRisk = await responseRisk.json();
        if (dataRisk.success === true) {
          setRisk(dataRisk.risk);
          setScores(dataRisk.scores);
          setLoaded(true);
        } else {
          throw new Error(dataRisk.error);
        }
      } else {
        throw new Error(responseRisk.statusText);
      }
    } catch (error) {
      setErrorMessage(error instanceof Error ? error.message : String(error));
      setError(true);
    }
  };

  return (
    <>
      <Header />
      <div className="dashboard-after">
        <Menu />
        <div className="assessment-view">

          <div className="form-title-wrapper">
            <div className="form-title">Deep Vein Thrombosis Risk Assessment</div>
            <button className="secondary-button form-button" onClick={resetCalculator}>Reset</button>
            <button className="primary-button form-button" onClick={calculateRisk} disabled={fetchingPatient || !selectedPatient}>Calculate</button>
          </div>

          <div className="form-wrapper">

            <SelectPatient setSelectedPatient={setupPatientData} setCalculatorData={setUpCalculatorData} setFetchingPatient={setFetchingPatient} />

            <div className="patient-data-input-wrapper">
              <div className="input-wrapper">
                <div className="input-label">Age:</div>
                <input type="text" className="text-input" aria-label="Age" value={selectedPatient?.age ?? ''} readOnly />
              </div>

              <div className="input-wrapper">
                <div className="input-label">Sex:</div>
                <input type="text" className="text-input" aria-label="Sex" value={selectedPatient?.sex ?? ''} readOnly />
              </div>

              <div className="input-wrapper">
                <div className="input-label">Race:</div>
                <input type="text" className="text-input" aria-label="Race" value={selectedPatient?.race ?? ''} readOnly />
              </div>

              <div className="input-wrapper">
                <div className="input-label">BMI:</div>
                <input type="text" className="text-input" aria-label="BMI" value={selectedPatient?.bmi ?? ''} readOnly />
              </div>
            </div>

            <div className="all-questions-wrapper">
              <div>
                <div className="question-wrapper">
                  <span className="question-number">1.</span>
                  <span className="question-text">Does the patient currently have active cancer (e.g., undergoing treatment, remission within 6 months)?</span>
                </div>

                <div className="answer-wrapper">
                  <label className="radio-button-wrapper">
                    <input type="radio" className="radio-button" name="q1" checked={checkIfChecked('active_cancer', 1)} onChange={() => handleCalculatorChange('active_cancer', 1)} />
                    <div className="radio-button-text">Yes</div>
                  </label>

                  <label className="radio-button-wrapper">
                    <input type="radio" className="radio-button" name="q1" checked={checkIfChecked('active_cancer', 0)} onChange={() => handleCalculatorChange('active_cancer', 0)} />
                    <div className="radio-button-text">No</div>
                  </label>
                </div>
              </div>

              <div>
                <div className="question-wrapper">
                  <span className="question-number">2.</span>
                  <span className="question-text">Has the patient been bedridden for more than 3 days or undergone major surgery within the last 12 weeks?</span>
                </div>

                <div className="answer-wrapper">
                  <label className="radio-button-wrapper">
                    <input type="radio" className="radio-button" name="q2" checked={checkIfChecked('bedridden_or_surgery', 1)} onChange={() => handleCalculatorChange('bedridden_or_surgery', 1)} />
                    <div className="radio-button-text">Yes</div>
                  </label>

                  <label className="radio-button-wrapper">
                    <input type="radio" className="radio-button" name="q2" checked={checkIfChecked('bedridden_or_surgery', 0)} onChange={() => handleCalculatorChange('bedridden_or_surgery', 0)} />
                    <div className="radio-button-text">No</div>
                  </label>
                </div>
              </div>

              <div>
                <div className="question-wrapper">
                  <span className="question-number">3.</span>
                  <span className="question-text">Is there swelling of the patient's calf that is greater than 3 cm compared to the other leg?</span>
                </div>

                <div className="answer-wrapper">
                  <label className="radio-button-wrapper">
                    <input type="radio" className="radio-button" name="q3" checked={checkIfChecked('calf_swelling', 1)} onChange={() => handleCalculatorChange('calf_swelling', 1)} />
                    <div className="radio-button-text">Yes</div>
                  </label>

                  <label className="radio-button-wrapper">
                    <input type="radio" className="radio-button" name="q3" checked={checkIfChecked('calf_swelling', 0)} onChange={() => handleCalculatorChange('calf_swelling', 0)} />
                    <div className="radio-button-text">No</div>
                  </label>
                </div>
              </div>

              <div>
                <div className="question-wrapper">
                  <span className="question-number">4.</span>
                  <span className="question-text">Are non-varicose collateral superficial veins present in the symptomatic leg?</span>
                </div>

                <div className="answer-wrapper">
                  <label className="radio-button-wrapper">
                    <input type="radio" className="radio-button" name="q4" checked={checkIfChecked('superficial_veins', 1)} onChange={() => handleCalculatorChange('superficial_veins', 1)} />
                    <div className="radio-button-text">Yes</div>
                  </label>

                  <label className="radio-button-wrapper">
                    <input type="radio" className="radio-button" name="q4" checked={checkIfChecked('superficial_veins', 0)} onChange={() => handleCalculatorChange('superficial_veins', 0)} />
                    <div className="radio-button-text">No</div>
                  </label>
                </div>
              </div>

              <div>
                <div className="question-wrapper">
                  <span className="question-number">5.</span>
                  <span className="question-text">Is the patient's entire leg swollen?</span>
                </div>

                <div className="answer-wrapper">
                  <label className="radio-button-wrapper">
                    <input type="radio" className="radio-button" name="q5" checked={checkIfChecked('leg_swollen', 1)} onChange={() => handleCalculatorChange('leg_swollen', 1)} />
                    <div className="radio-button-text">Yes</div>
                  </label>

                  <label className="radio-button-wrapper">
                    <input type="radio" className="radio-button" name="q5" checked={checkIfChecked('leg_swollen', 0)} onChange={() => handleCalculatorChange('leg_swollen', 0)} />
                    <div className="radio-button-text">No</div>
                  </label>
                </div>
              </div>

              <div>
                <div className="question-wrapper">
                  <span className="question-number">6.</span>
                  <span className="question-text">Is there localized tenderness along the deep venous system in the symptomatic leg?</span>
                </div>

                <div className="answer-wrapper">
                  <label className="radio-button-wrapper">
                    <input type="radio" className="radio-button" name="q6" checked={checkIfChecked('localized_dv_tenderness', 1)} onChange={() => handleCalculatorChange('localized_dv_tenderness', 1)} />
                    <div className="radio-button-text">Yes</div>
                  </label>

                  <label className="radio-button-wrapper">
                    <input type="radio" className="radio-button" name="q6" checked={checkIfChecked('localized_dv_tenderness', 0)} onChange={() => handleCalculatorChange('localized_dv_tenderness', 0)} />
                    <div className="radio-button-text">No</div>
                  </label>
                </div>
              </div>

              <div>
                <div className="question-wrapper">
                  <span className="question-number">7.</span>
                  <span className="question-text">Is there pitting edema confined to the symptomatic leg?</span>
                </div>

                <div className="answer-wrapper">
                  <label className="radio-button-wrapper">
                    <input type="radio" className="radio-button" name="q7" checked={checkIfChecked('pitting_edema', 1)} onChange={() => handleCalculatorChange('pitting_edema', 1)} />
                    <div className="radio-button-text">Yes</div>
                  </label>

                  <label className="radio-button-wrapper">
                    <input type="radio" className="radio-button" name="q7" checked={checkIfChecked('pitting_edema', 0)} onChange={() => handleCalculatorChange('pitting_edema', 0)} />
                    <div className="radio-button-text">No</div>
                  </label>
                </div>
              </div>

              <div>
                <div className="question-wrapper">
                  <span className="question-number">8.</span>
                  <span className="question-text">Does the patient have paralysis, paresis, or recent plaster immobilization of the lower extremity?</span>
                </div>

                <div className="answer-wrapper">
                  <label className="radio-button-wrapper">
                    <input type="radio" className="radio-button" name="q8" checked={checkIfChecked('extremity_immobilization', 1)} onChange={() => handleCalculatorChange('extremity_immobilization', 1)} />
                    <div className="radio-button-text">Yes</div>
                  </label>

                  <label className="radio-button-wrapper">
                    <input type="radio" className="radio-button" name="q8" checked={checkIfChecked('extremity_immobilization', 0)} onChange={() => handleCalculatorChange('extremity_immobilization', 0)} />
                    <div className="radio-button-text">No</div>
                  </label>
                </div>
              </div>

              <div>
                <div className="question-wrapper">
                  <span className="question-number">9.</span>
                  <span className="question-text">Has the patient had a previously documented DVT?</span>
                </div>

                <div className="answer-wrapper">
                  <label className="radio-button-wrapper">
                    <input type="radio" className="radio-button" name="q9" checked={checkIfChecked('previous_dvt', 1)} onChange={() => handleCalculatorChange('previous_dvt', 1)} />
                    <div className="radio-button-text">Yes</div>
                  </label>

                  <label className="radio-button-wrapper">
                    <input type="radio" className="radio-button" name="q9" checked={checkIfChecked('previous_dvt', 0)} onChange={() => handleCalculatorChange('previous_dvt', 0)} />
                    <div className="radio-button-text">No</div>
                  </label>
                </div>
              </div>

              <div>
                <div className="question-wrapper">
                  <span className="question-number">10.</span>
                  <span className="question-text">Is there an alternative diagnosis that is as likely or more likely than DVT?</span>
                </div>

                <div className="answer-wrapper">
                  <label className="radio-button-wrapper">
                    <input type="radio" className="radio-button" name="q10" checked={checkIfChecked('alternative_diagnosis', 1)} onChange={() => handleCalculatorChange('alternative_diagnosis', 1)} />
                    <div className="radio-button-text">Yes</div>
                  </label>

                  <label className="radio-button-wrapper">
                    <input type="radio" className="radio-button" name="q10" checked={checkIfChecked('alternative_diagnosis', 0)} onChange={() => handleCalculatorChange('alternative_diagnosis', 0)} />
                    <div className="radio-button-text">No</div>
                  </label>
                </div>
              </div>
            </div>

          </div>

          {loaded && (<ShowDVTRisk risk={risk} scores={scores} calculatorData={calculatorData} patientData={selectedPatient} />)}
          {loaded && (<AskAi risk={risk} calculator={3} calculatorData={calculatorData} patientData={selectedPatient} />)}

          <Footer />
        </div>
      </div>
    </>
  );
};
