import React, { ReactNode, createContext, useState, useEffect, useContext } from "react";
import { getJSON } from "../utils/utils";

export type User = {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  role: string;
  organization: string;
  organizationLocation: string;
};

type SessionUserContextType = {
  isLoading: boolean;
  sessionUser: User | null;
  setSessionUser: React.Dispatch<React.SetStateAction<User | null>>;
}

type SessionUserProviderProps = {
  children: ReactNode;
}

const SessionUserContext = createContext<SessionUserContextType | null>(null);

export const SessionUserProvider: React.FC<SessionUserProviderProps> = ({ children }: SessionUserProviderProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const [sessionUser, setSessionUser] = useState<User | null>(null);

  useEffect(() => {
    const fetchSessionUser = async () => {
      const response = await getJSON("/api/user/session");
      if ("id" in response) {
        setSessionUser(response);
      } else {
        setSessionUser(null);
      }

      setIsLoading(false);
    };

    fetchSessionUser();
  }, []);

  return (
    <SessionUserContext.Provider value={{ isLoading, sessionUser, setSessionUser }}>
      {children}
    </SessionUserContext.Provider>
  );
};

export const useSessionUser = (): SessionUserContextType  => {
  const context = useContext(SessionUserContext);
  if (context === null) {
    throw new Error("Invalid use of SessionUserContext");
  }
  return context;
};
