
const fetchJSON = async (url: string, method: string, data: any = null) => {
  try {
    const options: RequestInit = {
      method: method,
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json"
      }
    };

    if (data) {
      options.body = JSON.stringify(data);
    }

    const response = await fetch(url, options);

    if (!response.ok) {
      throw new Error(response.statusText);
    }

    const responseData = await response.json();

    if (!responseData) {
      throw new Error("response not JSON");
    }

    return responseData;

  } catch (error) {
    if (error instanceof Error) {
      return {error: error.message};
    }
  }

  return {error: "unknown error"};
}

export const getJSON = async (url: string) => {
  return fetchJSON(url, "GET");
}

export const postJSON = async (url: string, data: any) => {
  return fetchJSON(url, "POST", data);
}