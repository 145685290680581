import React from "react";
import { Link } from "react-router-dom";
import "./style.css";

import { useSessionUser } from "../SessionUserProvider";
import FHIRIslandLogo from "../static/img/fhir_island_logo.svg";
import ImageMagnifying from "../static/img/header/magnifying_glass.svg";
import ImageMapPin from "../static/img/header/map_pin.svg";
import ImageCrosshair from "../static/img/header/crosshair.svg";
import ImageAvatar from "../static/img/header/avatar.webp";
import ImageBell from "../static/img/header/bell.svg";

export const Header = (): JSX.Element => {
  const { sessionUser } = useSessionUser();

  return (
    <div className="header-wrapper">

      <div className="header-logo-wrapper">
        <Link to="/">
          <img width="300" height="90" alt="FHIR Island logo" src={FHIRIslandLogo} />
        </Link>
      </div>

      <div className="header-search-wrapper">
        <img width="20" height="20" alt="Magnifying glass" src={ImageMagnifying} />
        <input className="header-search-textbox" type="text" placeholder="Find patient"></input>
        <img width="20" height="20" alt="Map pin" src={ImageMapPin} />
        <div className="header-search-location-text">Location</div>
        <img width="20" height="20" alt="Crosshair" src={ImageCrosshair} />
        <button className="primary-button header-search-button">Search</button>
      </div>

      <div className="header-username-wrapper">
        <div className="header-bell" style={{ backgroundImage: `url(${ImageBell})` }}>
          <div className="header-bell-dot" />
        </div>
        <Link to="/profile">
          <img width="36" height="36" className="header-avatar" alt="User image" src={ImageAvatar} />
        </Link>
        <Link to="/profile" className="header-username-link">
          <div className="header-username-text">
              {sessionUser ? `${sessionUser.firstName} ${sessionUser.lastName}` : "User Name"}
          </div>
        </Link>
      </div>

    </div>
  );
};
