import React from "react";
import "./style.css";
import KeyboardImage from "../static/img/keyboard.webp";
import HeadImage from "../static/img/head_icon.svg";
import TearImage from "../static/img/tear_icon.svg";

import { Menu } from "../Menu";
import { Header } from "../Header";
import { Footer } from "../Footer";

export const AboutView = (): JSX.Element => {
  return (
    <>
    <Header />
    <div className="dashboard-after">
      <Menu />
      <div className="about-view">

        <div className="top-info">
          <img width="489" height="322" alt="Man typing" src={KeyboardImage} />

          <div>
            <div className="bullet-list-group">
              <img width="42" height="52" alt="Head image" src={HeadImage} />
              <div className="bullet-list-wrapper">
                <div className="bullet-list-title">Process</div>
                <ul className="bullet-list">
                  <li>Choose the Calculator</li>
                  <li>Enter Additional Details</li>
                  <li>Generate Risk Analytics</li>
                  <li>Review Recommendations</li>
                  <li>Repeat for Other Conditions</li>
                </ul>
              </div>
            </div>

            <div className="bullet-list-group">
              <img width="42" height="52" alt="Tear image" src={TearImage} />
              <div className="bullet-list-wrapper">
                <div className="bullet-list-title">Use Cases</div>
                <ul className="bullet-list">
                  <li>Preventive Care Planning</li>
                  <li>Pre-Surgical Risk Assessment</li>
                  <li>High-Risk Patient Identification</li>
                  <li>Follow-Up Appointments</li>
                  <li>Patient Education and More</li>
                </ul>
              </div>
            </div>
          </div>

        </div>

        <div className="fhir-title">FHIR-Based Disease Risk</div>
        <div className="fhir-title">Assessment Platform</div>
        <div className="fhir-info-text">
          Our FHIR-based disease risk assessment platform features
          three powerful calculators designed to help healthcare
          professionals assess patient risks and deliver personalized
          care. The Colorectal Cancer Risk Calculator, Chronic Kidney
          Disease Risk Calculator, and Deep Vein Thrombosis Risk
          Calculator use FHIR data and AI-powered insights from
          Anthropic Claude and OpenAI ChatGPT to provide accurate risk
          assessments. Seamlessly integrated into your workflow, these
          tools offer predictive analytics, intuitive dashboards, and
          tailored recommendations, enabling you to make data-driven
          decisions with ease and confidence.
        </div>

        <div className="fun-fact-wrapper">
          <div className="fun-fact-title">Fun fact :</div>
          <div className="fun-fact-info-text">
            Did you know AI in healthcare has the potential to <b>improve diagnostic
            accuracy by up to 40%</b>, reducing misdiagnoses and ensuring patients
            receive the right treatment faster than ever before; according to a
            study by McKinsey &amp; Company.
          </div>
        </div>

        <Footer />
      </div>

    </div>
    </>
  );
};
