import React from "react";

import BmiCircle from "../static/img/assessment/bmi_circle.svg";
import BmiColorBar from "../static/img/assessment/bmi_color_bar.svg";

interface ShowCKDRiskProps {
    risk: number;
    calculatorData: any;
    patientData: any;
    scores: any;
}

const ShowCKDRisk: React.FC<ShowCKDRiskProps> = ({ risk, scores, calculatorData, patientData }) => {
    const [riskScore, setRiskScore] = React.useState<number>(risk);
    const [riskLevel, setRiskLevel] = React.useState<string>(risk < 2 ? "Further Screening Not Indicated" : "Further Screening Indicated");
    const [riskDisplayLevel, setRiskDisplayLevel] = React.useState<string>(risk < 2 ? "low" : "high");

    return (
        <>
            <div className="form-title-wrapper">
                <div className="form-title">Calculator Results</div>
            </div>
            <div className="form-wrapper">
                <div className="widgets-wrapper">
                    <div className="bmi-height-group">
                        <div className="bmi-wrapper">
                            <div className="bmi-title">Risk Score</div>
                            <div className="bmi-values-wrapper">
                                <div className="bmi-value">{riskScore}</div>
                                <div className={`bmi-status bmi-status-${riskDisplayLevel}`}>
                                    {riskLevel}
                                </div>
                            </div>
                            <div className="bmi-bar-wrapper">
                                <img width="8" height="8" className={`bmi-circle-${riskDisplayLevel}`} alt="BMI circle" src={BmiCircle} />
                                <img width="239" height="12" alt="BMI bar" src={BmiColorBar} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ShowCKDRisk;
