import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./dashboard-patient-style.css";

import UserAvatar from "../static/img/user_avatar.webp";

interface DashboardPatientProps {
  id: number;
  name: string;
  address: string;
}

function DashboardPatient({ id, name, address }: DashboardPatientProps) {
  return (
    <div className="patient-list-item">
      <div className="patient-list-item-wrapper">
        <img width="63" height="63" alt="User Avatar" src={UserAvatar} />
        <div className="patient-list-info">
          <div className="patient-list-name">{name}</div>
          <div className="patient-list-location">{address}</div>
        </div>
      </div>
      <div className="patient-list-id">Patient ID: {id}</div>
    </div>
  );
}

export default DashboardPatient;
