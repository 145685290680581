import React from "react";

interface ShowRiskWidgetProps {
  riskTitle: string;
  riskValue: number;
}

const ShowRiskWidget: React.FC<ShowRiskWidgetProps> = ({ riskTitle, riskValue }) => {
  const risk = (riskValue - 1) * 100;
  const widgetStyle = risk < 0 ? "percent-blue-bg" : risk == 0 ? "percent-green-bg" : "percent-red-bg";
  return (
    <>
      <div className={`percent-wrapper ${widgetStyle}`}>
        <div className="percent-title-wrapper">{riskTitle}</div>
        <div className="percent-result-wrapper">{((riskValue - 1) * 100).toFixed(2)}%</div>
      </div>
    </>
  );
}

export default ShowRiskWidget;
