import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSessionUser } from "../SessionUserProvider";
import { postJSON } from "../utils/utils";

export const LogoutPage = (): JSX.Element => {
  const { setSessionUser } = useSessionUser();
  const navigate = useNavigate();

  useEffect(() => {
    postJSON("/api/logout", {});
    setSessionUser(null);
    navigate("/login");
  }, []);

  return (
    <div>Logging out</div>
  );
};
