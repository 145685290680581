import React, { useState } from "react";
import "./style.css";

import { Menu } from "../Menu";
import { Header } from "../Header";
import { Footer } from "../Footer";
import SelectPatient from "./SelectPatient";
import ShowCKDRisk from "./ShowCKDRisk";
import AskAi from "./AskAi";

export const ChronicKidney = (): JSX.Element => {
  const [selectedPatient, setSelectedPatient] = useState<any>(null);
  const [calculatorData, setCalculatorData] = useState<any>({
    bmi: 0,
    age: 0,
    has_diabetes: 0,
    has_hypertension: 0,
    cardiovascular_disease_history: 0,
    family_kidney_failure: 0,
    is_smoker: 0,
    acute_kidney_injury: 0,
    nephrotoxic_drugs: 0,
    urine_protein: 0,
    bad_gfr: 0,
    fluid_retention: 0,
  });
  const [defaultCalculatorData, setDefaultCalculatorData] = useState(calculatorData);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [risk, setRisk] = useState<number>(0);
  const [scores, setScores] = useState<any>({});
  const [fetchingPatient, setFetchingPatient] = useState<boolean>(false);

  const setUpCalculatorData = (data: any) => {
    setCalculatorData(data);
    setDefaultCalculatorData(data);
  };

  const setupPatientData = (data: any) => {
    setSelectedPatient(data);
    setLoaded(false);
  };

  const handleCalculatorChange = (key: string, value: any) => {
    setCalculatorData({
      ...calculatorData,
      [key]: value
    });
  };

  const checkIfChecked = (key: string, value: any) => {
    return calculatorData[key] === value;
  };

  const resetCalculator = () => {
    setCalculatorData(defaultCalculatorData);
  };

  const calculateRisk = async () => {
    setLoaded(false);
    try {
      const responseRisk = await fetch('/api/calculate_risks/2', {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...calculatorData,
          ...selectedPatient
        })
      });
      if (responseRisk.ok) {
        const dataRisk = await responseRisk.json();
        if (dataRisk.success === true) {
          setRisk(dataRisk.risk);
          setScores(dataRisk.scores);
          setLoaded(true);
        } else {
          throw new Error(dataRisk.error);
        }
      } else {
        throw new Error(responseRisk.statusText);
      }
    } catch (error) {
      setErrorMessage(error instanceof Error ? error.message : String(error));
      setError(true);
    }
  };

  return (
    <>
      <Header />
      <div className="dashboard-after">
        <Menu />
        <div className="assessment-view">

          <div className="form-title-wrapper">
            <div className="form-title">Chronic Kidney Disease Risk Assessment</div>
            <button className="secondary-button form-button" onClick={resetCalculator}>Reset</button>
            <button className="primary-button form-button" onClick={calculateRisk} disabled={fetchingPatient || !selectedPatient}>Calculate</button>
          </div>

          <div className="form-wrapper">

            <SelectPatient setSelectedPatient={setupPatientData} setCalculatorData={setUpCalculatorData} setFetchingPatient={setFetchingPatient} />

            <div className="patient-data-input-wrapper">
              <div className="input-wrapper">
                <div className="input-label">Age:</div>
                <input type="text" className="text-input" aria-label="Age" value={selectedPatient?.age ?? ''} readOnly />
              </div>

              <div className="input-wrapper">
                <div className="input-label">Sex:</div>
                <input type="text" className="text-input" aria-label="Sex" value={selectedPatient?.sex ?? ''} readOnly />
              </div>

              <div className="input-wrapper">
                <div className="input-label">Race:</div>
                <input type="text" className="text-input" aria-label="Race" value={selectedPatient?.race ?? ''} readOnly />
              </div>

              <div className="input-wrapper">
                <div className="input-label">BMI:</div>
                <input type="text" className="text-input" aria-label="BMI" value={selectedPatient?.bmi ?? ''} readOnly />
              </div>
            </div>

            <div className="all-questions-wrapper">
              <div>
                <div className="question-wrapper">
                  <span className="question-number">1.</span>
                  <span className="question-text">Does the patient have a diagnosis of diabetes?</span>
                </div>

                <div className="answer-wrapper">
                  <label className="radio-button-wrapper">
                    <input type="radio" className="radio-button" name="q1" checked={checkIfChecked('has_diabetes', 1)} onChange={() => handleCalculatorChange('has_diabetes', 1)} />
                    <div className="radio-button-text">Yes</div>
                  </label>

                  <label className="radio-button-wrapper">
                    <input type="radio" className="radio-button" name="q1" checked={checkIfChecked('has_diabetes', 0)} onChange={() => handleCalculatorChange('has_diabetes', 0)} />
                    <div className="radio-button-text">No</div>
                  </label>
                </div>
              </div>

              <div>
                <div className="question-wrapper">
                  <span className="question-number">2.</span>
                  <span className="question-text">Has the patient been diagnosed with high blood pressure (hypertension)?</span>
                </div>

                <div className="answer-wrapper">
                  <label className="radio-button-wrapper">
                    <input type="radio" className="radio-button" name="q2" checked={checkIfChecked('has_hypertension', 1)} onChange={() => handleCalculatorChange('has_hypertension', 1)} />
                    <div className="radio-button-text">Yes</div>
                  </label>

                  <label className="radio-button-wrapper">
                    <input type="radio" className="radio-button" name="q2" checked={checkIfChecked('has_hypertension', 0)} onChange={() => handleCalculatorChange('has_hypertension', 0)} />
                    <div className="radio-button-text">No</div>
                  </label>
                </div>
              </div>

              <div>
                <div className="question-wrapper">
                  <span className="question-number">3.</span>
                  <span className="question-text">Does the patient have a history of cardiovascular disease?</span>
                </div>

                <div className="answer-wrapper">
                  <label className="radio-button-wrapper">
                    <input type="radio" className="radio-button" name="q3" checked={checkIfChecked('cardiovascular_disease_history', 1)} onChange={() => handleCalculatorChange('cardiovascular_disease_history', 1)} />
                    <div className="radio-button-text">Yes</div>
                  </label>

                  <label className="radio-button-wrapper">
                    <input type="radio" className="radio-button" name="q3" checked={checkIfChecked('cardiovascular_disease_history', 0)} onChange={() => handleCalculatorChange('cardiovascular_disease_history', 0)} />
                    <div className="radio-button-text">No</div>
                  </label>
                </div>
              </div>

              <div>
                <div className="question-wrapper">
                  <span className="question-number">4.</span>
                  <span className="question-text">Is there a family history of kidney failure or related conditions?</span>
                </div>

                <div className="answer-wrapper">
                  <label className="radio-button-wrapper">
                    <input type="radio" className="radio-button" name="q4" checked={checkIfChecked('family_kidney_failure', 1)} onChange={() => handleCalculatorChange('family_kidney_failure', 1)} />
                    <div className="radio-button-text">Yes</div>
                  </label>

                  <label className="radio-button-wrapper">
                    <input type="radio" className="radio-button" name="q4" checked={checkIfChecked('family_kidney_failure', 0)} onChange={() => handleCalculatorChange('family_kidney_failure', 0)} />
                    <div className="radio-button-text">No</div>
                  </label>
                </div>
              </div>

              <div>
                <div className="question-wrapper">
                  <span className="question-number">5.</span>
                  <span className="question-text">Does the patient smoke or have a history of smoking?</span>
                </div>

                <div className="answer-wrapper">
                  <label className="radio-button-wrapper">
                    <input type="radio" className="radio-button" name="q5" checked={checkIfChecked('is_smoker', 1)} onChange={() => handleCalculatorChange('is_smoker', 1)} />
                    <div className="radio-button-text">Yes</div>
                  </label>

                  <label className="radio-button-wrapper">
                    <input type="radio" className="radio-button" name="q5" checked={checkIfChecked('is_smoker', 0)} onChange={() => handleCalculatorChange('is_smoker', 0)} />
                    <div className="radio-button-text">No</div>
                  </label>
                </div>
              </div>

              <div>
                <div className="question-wrapper">
                  <span className="question-number">6.</span>
                  <span className="question-text">Has the patient experienced acute kidney injury in the past?</span>
                </div>

                <div className="answer-wrapper">
                  <label className="radio-button-wrapper">
                    <input type="radio" className="radio-button" name="q6" checked={checkIfChecked('acute_kidney_injury', 1)} onChange={() => handleCalculatorChange('acute_kidney_injury', 1)} />
                    <div className="radio-button-text">Yes</div>
                  </label>

                  <label className="radio-button-wrapper">
                    <input type="radio" className="radio-button" name="q6" checked={checkIfChecked('acute_kidney_injury', 0)} onChange={() => handleCalculatorChange('acute_kidney_injury', 0)} />
                    <div className="radio-button-text">No</div>
                  </label>
                </div>
              </div>

              <div>
                <div className="question-wrapper">
                  <span className="question-number">7.</span>
                  <span className="question-text">Has the patient been exposed to nephrotoxic drugs (e.g., NSAIDs, certain antibiotics)?</span>
                </div>

                <div className="answer-wrapper">
                  <label className="radio-button-wrapper">
                    <input type="radio" className="radio-button" name="q7" checked={checkIfChecked('nephrotoxic_drugs', 1)} onChange={() => handleCalculatorChange('nephrotoxic_drugs', 1)} />
                    <div className="radio-button-text">Yes</div>
                  </label>

                  <label className="radio-button-wrapper">
                    <input type="radio" className="radio-button" name="q7" checked={checkIfChecked('nephrotoxic_drugs', 0)} onChange={() => handleCalculatorChange('nephrotoxic_drugs', 0)} />
                    <div className="radio-button-text">No</div>
                  </label>
                </div>
              </div>

              <div>
                <div className="question-wrapper">
                  <span className="question-number">8.</span>
                  <span className="question-text">Has the patient shown elevated protein levels in their urine during previous tests?</span>
                </div>

                <div className="answer-wrapper">
                  <label className="radio-button-wrapper">
                    <input type="radio" className="radio-button" name="q8" checked={checkIfChecked('urine_protein', 1)} onChange={() => handleCalculatorChange('urine_protein', 1)} />
                    <div className="radio-button-text">Yes</div>
                  </label>

                  <label className="radio-button-wrapper">
                    <input type="radio" className="radio-button" name="q8" checked={checkIfChecked('urine_protein', 0)} onChange={() => handleCalculatorChange('urine_protein', 0)} />
                    <div className="radio-button-text">No</div>
                  </label>
                </div>
              </div>

              <div>
                <div className="question-wrapper">
                  <span className="question-number">9.</span>
                  <span className="question-text">Has the patient's GFR been below 60 mL/min/1.73 m² during recent tests?</span>
                </div>

                <div className="answer-wrapper">
                  <label className="radio-button-wrapper">
                    <input type="radio" className="radio-button" name="q9" checked={checkIfChecked('bad_gfr', 1)} onChange={() => handleCalculatorChange('bad_gfr', 1)} />
                    <div className="radio-button-text">Yes</div>
                  </label>

                  <label className="radio-button-wrapper">
                    <input type="radio" className="radio-button" name="q9" checked={checkIfChecked('bad_gfr', 0)} onChange={() => handleCalculatorChange('bad_gfr', 0)} />
                    <div className="radio-button-text">No</div>
                  </label>
                </div>
              </div>

              <div>
                <div className="question-wrapper">
                  <span className="question-number">10.</span>
                  <span className="question-text">Has the patient experienced persistent fluid retention or swelling in their legs, feet, or other areas?</span>
                </div>

                <div className="answer-wrapper">
                  <label className="radio-button-wrapper">
                    <input type="radio" className="radio-button" name="q10" checked={checkIfChecked('fluid_retention', 1)} onChange={() => handleCalculatorChange('fluid_retention', 1)} />
                    <div className="radio-button-text">Yes</div>
                  </label>

                  <label className="radio-button-wrapper">
                    <input type="radio" className="radio-button" name="q10" checked={checkIfChecked('fluid_retention', 0)} onChange={() => handleCalculatorChange('fluid_retention', 0)} />
                    <div className="radio-button-text">No</div>
                  </label>
                </div>
              </div>
            </div>

          </div>

          {loaded && (<ShowCKDRisk risk={risk} scores={scores} calculatorData={calculatorData} patientData={selectedPatient} />)}
          {loaded && (<AskAi risk={risk} calculator={2} calculatorData={calculatorData} patientData={selectedPatient} />)}

          <Footer />
        </div>
      </div>
    </>
  );
};
