import React from "react";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import "./style.css";

import { Menu } from "../Menu";
import { Header } from "../Header";
import { Footer } from "../Footer";

export const CalendarView = (): JSX.Element => {
  return (
    <>
      <Header />
      <div className="dashboard-after">
        <Menu />
        <div className="calendar-container">
          <div className="calendar-title">Calendar</div>
          <Calendar />
          <Footer />
        </div>
      </div>
    </>
  );
};
