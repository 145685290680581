import React, { useState }  from "react";
import { Link, useNavigate } from "react-router-dom";
import "./style.css";
import ImageLogo from "../static/img/GTExtended_RGB.svg";
import Cardio from "../static/img/signup_cardio.svg";
import FHIRIslandLogo from "../static/img/fhir_island_logo.svg";
import { postJSON } from "../utils/utils";
import { useSessionUser } from "../SessionUserProvider";

export const SignUp = (): JSX.Element => {
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [reEnterPassword, setReEnterPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const { setSessionUser } = useSessionUser();
  const navigate = useNavigate();
  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    reEnterPassword: ""
  });

  const handleFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFirstName(event.target.value);
  };

  const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLastName(event.target.value);
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const handleReEnterPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReEnterPassword(event.target.value);
  };

  const handleShowPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowPassword(!showPassword);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSignUp();
    }
  };

  const handleSignUp = async () => {
    const data = {
        firstName: firstName,
        lastName: lastName,
        email: email,
        password: password,
        reEnterPassword: reEnterPassword
    };

    const response = await postJSON("/api/create-account", data);
    const errorsData: any = {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      reEnterPassword: ""
    }

    if ("error" in response) {
      const items: any = response.error;
      for (const [key, value] of Object.entries(items)) {
        errorsData[key] = value;
      }
    } else {
      setSessionUser(response);
      navigate("/");
    }

    setErrors(errorsData);
  }

  return (
    <div className="new-user-sign-up">

      <div className="form-input-wrapper">
        <div className="create-an-account">Create an account</div>
        <div className="log-in-wrapper">
          <span>Already have an account?</span>
          <Link to="/login" className="log-in">Log in</Link>
        </div>

        <div className="name-wrapper">
          <div>
            <div className="input-label">First Name:</div>

            <input
              type="text"
              className={`small-input text-input ${errors.firstName ? 'error-border' : ''}`}
              aria-label="First Name"
              onChange={handleFirstNameChange}
              onKeyDown={handleKeyDown} />

            {errors.firstName &&
              <div className="form-error">{errors.firstName}</div>
            }
          </div>

          <div>
            <div className="input-label">Last Name:</div>

            <input
              type="text"
              className={`small-input text-input ${errors.lastName ? 'error-border' : ''}`}
              aria-label="Last Name"
              onChange={handleLastNameChange}
              onKeyDown={handleKeyDown} />

            {errors.lastName &&
              <div className="form-error">{errors.lastName}</div>
            }
          </div>
        </div>

        <div>
          <div className="input-label">Email:</div>

          <input
            type="text"
            className={`large-input text-input ${errors.email ? 'error-border' : ''}`}
            aria-label="Email"
            onChange={handleEmailChange}
            onKeyDown={handleKeyDown} />

          {errors.email &&
            <div className="form-error">{errors.email}</div>
          }
        </div>

        <div>
          <div className="input-label">Password:</div>

          <input
            type={showPassword ? 'text' : 'password'}
            className={`large-input text-input ${errors.password ? 'error-border' : ''}`}
            aria-label="Password"
            placeholder="At least 6 characters"
            onChange={handlePasswordChange}
            onKeyDown={handleKeyDown} />

          {errors.password &&
            <div className="form-error">{errors.password}</div>
          }
        </div>

        <div>
          <div className="input-label">Re-Enter Password:</div>

          <input
            type={showPassword ? 'text' : 'password'}
            className={`large-input text-input ${errors.reEnterPassword ? 'error-border' : ''}`}
            aria-label="Re-Enter Password"
            onChange={handleReEnterPasswordChange}
            onKeyDown={handleKeyDown} />

          {errors.reEnterPassword &&
            <div className="form-error">{errors.reEnterPassword}</div>
          }
        </div>

        <label className="show-password-wrapper">
          <input type="checkbox" className="show-password-checkbox" onChange={handleShowPasswordChange} />
          <div>Show password</div>
        </label>

        <button className="primary-button sign-up-button" onClick={handleSignUp}>Sign Up</button>
      </div>

      <div className="logo-wrapper">
        <div>
          <img width="707" height="273" alt="FHIR Island logo" src={FHIRIslandLogo} />
          <div className="app-description">Your All-in-One Risk Calculator Hub for Smarter, Faster Medical Decisions</div>
        </div>
        <img width="413" height="389" className="heart-cardiogram-icon" alt="Cardiogram icon" src={Cardio} />
        <img width="186" height="42" className="georgia-tech-logo" alt="Georgia tech logo" src={ImageLogo} />
      </div>

    </div>
  );
}
