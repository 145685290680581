import React from "react";
import { Link } from "react-router-dom";
import "./style.css";

import { Menu } from "../Menu";
import { Header } from "../Header";
import { Footer } from "../Footer";

export const CalculatorHomePage = (): JSX.Element => {
  return (
  <>
    <Header />
    <div className="dashboard-after">
      <Menu />
      <div className="calculator-page-wrapper">
        <div className="main-title">Health Assessments</div>
        <div className="main-info-text">
          Enhance your diagnostic accuracy and confidence with these
          advanced health calculators, designed to provide data-driven
          insights for evaluating the likelihood of specific conditions.
        </div>
        <div className="main-info-text">
          Choose from <b>Colorectal Cancer, Deep Vein Thrombosis,</b> or <b>Chronic Kidney Disease </b>
          calculators to analyze patient data and support informed clinical decisions
          with precision and efficiency.
        </div>

      <div className="calculators-container">

        <div className="calculator-item-wrapper">
          <div className="calculator-item-title">Deep Vein Thrombosis Risk</div>
          <div className="calculator-item-subtitle">Blood Clots or DVT</div>
          <Link to="/calculator/3" className="primary-button calculator-item-btn">Calculate</Link>
          <div className="calculator-item-text">Symptoms include leg swelling, tenderness, redness, and warmth.</div>
        </div>

        <div className="calculator-item-wrapper">
          <div className="calculator-item-title">Colon Cancer Risk</div>
          <div className="calculator-item-subtitle">Genetic Risk, Family History, and Personal Factors</div>
          <Link to="/calculator/1" className="primary-button calculator-item-btn">Calculate</Link>
          <div className="calculator-item-text">Symptoms include blood in stool, unexplained weight loss, and persistent abdominal discomfort.</div>
        </div>

        <div className="calculator-item-wrapper">
          <div className="calculator-item-title">Chronic Kidney Disease Risk</div>
          <div className="calculator-item-subtitle">Probability of Having Stage 3-5 CKD</div>
          <Link to="/calculator/2" className="primary-button calculator-item-btn">Calculate</Link>
          <div className="calculator-item-text">Symptoms include fatigue, swelling in the legs or face, decreased urine output, and high blood pressure.</div>
        </div>

      </div>

      <Footer />
      </div>
    </div>
  </>
  );
};
