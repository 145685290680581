import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./style.css";

import { ReactComponent as ImageCircles } from "../static/img/menu/circles_four.svg";
import { ReactComponent as ImageCalendar } from "../static/img/menu/calendar_blank.svg";
import { ReactComponent as ImageUser } from "../static/img/menu/user_circle.svg";
import { ReactComponent as ImagePie } from "../static/img/menu/pie_chart.svg";
import { ReactComponent as ImageQuestion } from "../static/img/menu/question.svg";
import { ReactComponent as ImageSignout } from "../static/img/menu/sign_out.svg";

export const Menu = (): JSX.Element => {
  const location = useLocation();
  const [hoveredLink, setHoveredLink] = useState<string | null>(null);

  const getLinkClass = (path: string) => {
    if (location.pathname === path || hoveredLink === path || (path != '/' && location.pathname.includes(path))) {
      return "menu-link menu-link-active";
    }
    return "menu-link";
  };

  return (
    <>
      <div className="menu-sidebar">
        <Link
          to="/"
          className={getLinkClass("/")}
          onMouseEnter={() => setHoveredLink("/")}
          onMouseLeave={() => setHoveredLink(null)}
        >
          <div className="menu-link-wrapper">
            <ImageCircles className="menu-img" />
            <div className="menu-link-text">Dashboard</div>
          </div>
        </Link>

        <Link
          to="/calendar"
          className={getLinkClass("/calendar")}
          onMouseEnter={() => setHoveredLink("/calendar")}
          onMouseLeave={() => setHoveredLink(null)}
        >
          <div className="menu-link-wrapper">
            <ImageCalendar className="menu-img" />
            <div className="menu-link-text">Calendar</div>
          </div>
        </Link>

        <Link
          to="/profile"
          className={getLinkClass("/profile")}
          onMouseEnter={() => setHoveredLink("/profile")}
          onMouseLeave={() => setHoveredLink(null)}
        >
          <div className="menu-link-wrapper">
            <ImageUser className="menu-img" />
            <div className="menu-link-text">Profile</div>
          </div>
        </Link>

        <Link
          to="/calculator"
          className={getLinkClass("/calculator")}
          onMouseEnter={() => setHoveredLink("/calculator")}
          onMouseLeave={() => setHoveredLink(null)}
        >
          <div className="menu-link-wrapper">
            <ImagePie className="menu-img" />
            <div className="menu-link-text">Calculator</div>
          </div>
        </Link>

        <Link
          to="/about"
          className={getLinkClass("/about")}
          onMouseEnter={() => setHoveredLink("/about")}
          onMouseLeave={() => setHoveredLink(null)}
        >
          <div className="menu-link-wrapper">
            <ImageQuestion className="menu-img" />
            <div className="menu-link-text">About</div>
          </div>
        </Link>

        <Link
          to="/logout"
          className={getLinkClass("/logout")}
          onMouseEnter={() => setHoveredLink("/logout")}
          onMouseLeave={() => setHoveredLink(null)}
        >
          <div className="menu-link-wrapper">
            <ImageSignout className="menu-img" />
            <div className="menu-link-text">Logout</div>
          </div>
        </Link>
      </div>
    </>
  );
};
