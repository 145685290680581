import React, { useEffect, useState } from "react";
import Markdown from 'react-markdown'

import GPTIcon from "../static/img/assessment/gpt_fhir_island_icon.svg";
import Redo from "../static/img/assessment/redo.svg";

interface AskAiProps {
    risk: number;
    calculatorData: any;
    patientData: any;
    calculator: number;
}

const AskAi: React.FC<AskAiProps> = ({ risk, calculator, calculatorData, patientData }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [advice, setAdvice] = useState<string>("");

  useEffect(() => {
    fetchAiData();
  }, []);

  const fetchAiData = async () => {
    setLoading(true);
    try {
      const response = await fetch('/api/ask_ai', {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          calculator: calculator,
          risk: risk,
          calculatorData: calculatorData,
          patientData: patientData
        })
      });
      if (response.ok) {
        const dataAi = await response.json();
        if (dataAi.success === true) {
          setAdvice(dataAi.advice);
        } else {
          throw new Error(dataAi.error);
        }
      } else {
        throw new Error(response.statusText);
      }
    } catch (error) {
      setErrorMessage(error instanceof Error ? error.message : String(error));
      setError(true);
    }
    setLoading(false);
  };

  return (
    <>
      <div className="form-title-wrapper">
        <div className="form-title">AI-Driven Patient Recommendations</div>
        <button className="primary-button regenerate-button" onClick={fetchAiData}>
          <img width="15" height="15" alt="Redo icon" src={Redo} />
          <span>Regenerate Response</span>
        </button>
      </div>

      <div className="ai-container">

        <div className="ai-response-container">
          <div className="ai-response-header">
            <img width="24" height="24" alt="GPT FHIR Island icon" src={GPTIcon} />
            <div className="ai-response-title">FHIR Island GPT</div>
          </div>
          <div className="ai-text-output">
            {loading && <p>Loading...</p>}
            {error && <div className="error-message">{errorMessage}</div>}
            {!loading && !error && (
              <Markdown>{advice}</Markdown>
            )}
          </div>
        </div>

        <div className="ai-warning-text">AI driven recommendations can make mistakes. Consider checking important information.</div>
      </div>
    </>
  );
}

export default AskAi;