import React, { useState } from "react";
import "./style.css";

import { Menu } from "../Menu";
import { Header } from "../Header";
import { Footer } from "../Footer";
import SelectPatient from "./SelectPatient";
import ShowRisk from "./ShowRisk";
import AskAi from "./AskAi";

export const ColorectalCancer = (): JSX.Element => {
  const [selectedPatient, setSelectedPatient] = useState<any>(null);
  const [calculatorData, setCalculatorData] = useState<any>({
    colorectal_family_history: true,
    colorectal_screening_history: 0,
    daily_cigs: 0,
    estrogen: true,
    nsaid_use: true,
    symptoms: true,
    veg_servings: 0,
    vigorous_exercise: true,
    weekly_exercise: 1
  });
  const [defaultCalculatorData, setDefaultCalculatorData] = useState(calculatorData);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [risk, setRisk] = useState<number>(0);
  const [scores, setScores] = useState<any>({});
  const [fetchingPatient, setFetchingPatient] = useState<boolean>(false);

  const setUpCalculatorData = (data: any) => {
    setCalculatorData(data);
    setDefaultCalculatorData(data);
  };

  const setupPatientData = (data: any) => {
    setSelectedPatient(data);
    setLoaded(false);
  };

  const handleCalculatorChange = (key: string, value: any) => {
    setCalculatorData({
      ...calculatorData,
      [key]: value
    });
  };

  const checkIfChecked = (key: string, value: any) => {
    return calculatorData[key] === value;
  };

  const resetCalculator = () => {
    setCalculatorData(defaultCalculatorData);
  };

  const calculateRisk = async () => {
    setLoaded(false);
    try {
      const responseRisk = await fetch('/api/calculate_risks/1', {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...calculatorData,
          ...selectedPatient
        })
      });
      if (responseRisk.ok) {
        const dataRisk = await responseRisk.json();
        if (dataRisk.success === true) {
          setRisk(dataRisk.risk);
          setScores(dataRisk.scores);
          setLoaded(true);
        } else {
          throw new Error(dataRisk.error);
        }
      } else {
        throw new Error(responseRisk.statusText);
      }
    } catch (error) {
      setErrorMessage(error instanceof Error ? error.message : String(error));
      setError(true);
    }
  };

  return (
    <>
      <Header />
      <div className="dashboard-after">
        <Menu />
        <div className="assessment-view">

          <div className="form-title-wrapper">
            <div className="form-title">Colorectal Cancer Risk Assessment</div>
            <button className="secondary-button form-button" onClick={resetCalculator}>Reset</button>
            <button className="primary-button form-button" onClick={calculateRisk} disabled={fetchingPatient || !selectedPatient}>Calculate</button>
          </div>

          <div className="form-wrapper">

            <SelectPatient setSelectedPatient={setupPatientData} setCalculatorData={setUpCalculatorData} setFetchingPatient={setFetchingPatient} />

            <div className="patient-data-input-wrapper">

              <div className="input-wrapper">
                <div className="input-label">Age:</div>
                <input type="text" className="text-input" aria-label="Age" value={selectedPatient?.age ?? ''} readOnly />
              </div>

              <div className="input-wrapper">
                <div className="input-label">Sex:</div>
                <input type="text" className="text-input" aria-label="Sex" value={selectedPatient?.sex ?? ''} readOnly />
              </div>

              <div className="input-wrapper">
                <div className="input-label">Race:</div>
                <input type="text" className="text-input" aria-label="Race" value={selectedPatient?.race ?? ''} readOnly />
              </div>

              <div className="input-wrapper">
                <div className="input-label">BMI:</div>
                <input type="text" className="text-input" aria-label="BMI" value={selectedPatient?.bmi ?? ''} readOnly />
              </div>

            </div>

            <div className="all-questions-wrapper">

              <div>
                <div className="question-wrapper">
                  <span className="question-number">1.</span>
                  <span className="question-text">Does the patient have a family history of colorectal cancer?</span>
                </div>

                <div className="answer-wrapper">
                  <label className="radio-button-wrapper">
                    <input type="radio" className="radio-button" name="q1" checked={checkIfChecked('colorectal_family_history', true)} onChange={() => handleCalculatorChange('colorectal_family_history', true)} />
                    <div className="radio-button-text">Yes</div>
                  </label>

                  <label className="radio-button-wrapper">
                    <input type="radio" className="radio-button" name="q1" checked={checkIfChecked('colorectal_family_history', false)} onChange={() => handleCalculatorChange('colorectal_family_history', false)} />
                    <div className="radio-button-text">No</div>
                  </label>
                </div>
              </div>

              <div>
                <div className="question-wrapper">
                  <span className="question-number">2.</span>
                  <span className="question-text">How many hours per week does the patient engage in physical activity?</span>
                </div>

                <div className="answer-wrapper">
                  <label className="radio-button-wrapper">
                    <input type="radio" className="radio-button" name="q2" checked={checkIfChecked('weekly_exercise', 1)} onChange={() => handleCalculatorChange('weekly_exercise', 1)} />
                    <div className="radio-button-text">0 - 2 Hours</div>
                  </label>

                  <label className="radio-button-wrapper">
                    <input type="radio" className="radio-button" name="q2" checked={checkIfChecked('weekly_exercise', 2)} onChange={() => handleCalculatorChange('weekly_exercise', 2)} />
                    <div className="radio-button-text">3 - 4 Hours</div>
                  </label>

                  <label className="radio-button-wrapper">
                    <input type="radio" className="radio-button" name="q2" checked={checkIfChecked('weekly_exercise', 3)} onChange={() => handleCalculatorChange('weekly_exercise', 3)} />
                    <div className="radio-button-text">5+ Hours</div>
                  </label>
                </div>
              </div>

              <div>
                <div className="question-wrapper">
                  <span className="question-number">3.</span>
                  <span className="question-text">Does the patient participate in vigorous exercise (e.g., running, intense sports)?</span>
                </div>

                <div className="answer-wrapper">
                  <label className="radio-button-wrapper">
                    <input type="radio" className="radio-button" name="q3" checked={checkIfChecked('vigorous_exercise', true)} onChange={() => handleCalculatorChange('vigorous_exercise', true)} />
                    <div className="radio-button-text">Yes</div>
                  </label>

                  <label className="radio-button-wrapper">
                    <input type="radio" className="radio-button" name="q3" checked={checkIfChecked('vigorous_exercise', false)} onChange={() => handleCalculatorChange('vigorous_exercise', false)} />
                    <div className="radio-button-text">No</div>
                  </label>
                </div>
              </div>

              <div>
                <div className="question-wrapper">
                  <span className="question-number">4.</span>
                  <span className="question-text">How many cigarettes does the patient smoke daily?</span>
                </div>

                <div className="answer-wrapper">
                  <label className="radio-button-wrapper">
                    <input type="radio" className="radio-button" name="q4" checked={checkIfChecked('daily_cigs', 0)} onChange={() => handleCalculatorChange('daily_cigs', 0)} />
                    <div className="radio-button-text">Never Smoked</div>
                  </label>

                  <label className="radio-button-wrapper">
                    <input type="radio" className="radio-button" name="q4" checked={checkIfChecked('daily_cigs', 1)} onChange={() => handleCalculatorChange('daily_cigs', 1)} />
                    <div className="radio-button-text">0 - 10</div>
                  </label>

                  <label className="radio-button-wrapper">
                    <input type="radio" className="radio-button" name="q4" checked={checkIfChecked('daily_cigs', 2)} onChange={() => handleCalculatorChange('daily_cigs', 2)} />
                    <div className="radio-button-text">11 - 20</div>
                  </label>

                  <label className="radio-button-wrapper">
                    <input type="radio" className="radio-button" name="q4" checked={checkIfChecked('daily_cigs', 3)} onChange={() => handleCalculatorChange('daily_cigs', 3)} />
                    <div className="radio-button-text">20 or More</div>
                  </label>
                </div>
              </div>

              <div>
                <div className="question-wrapper">
                  <span className="question-number">5.</span>
                  <span className="question-text">What is your colorectal cancer screening history?</span>
                </div>

                <div className="answer-wrapper">
                  <label className="radio-button-wrapper">
                    <input type="radio" className="radio-button" name="q5" checked={checkIfChecked('colorectal_screening_history', 0)} onChange={() => handleCalculatorChange('colorectal_screening_history', 0)} />
                    <div className="radio-button-text">Colonoscopy in the Last 10 Years, No Polyps</div>
                  </label>

                  <label className="radio-button-wrapper">
                    <input type="radio" className="radio-button" name="q5" checked={checkIfChecked('colorectal_screening_history', 1)} onChange={() => handleCalculatorChange('colorectal_screening_history', 1)} />
                    <div className="radio-button-text">No Colonoscopy in the Last 10 Years</div>
                  </label>

                  <label className="radio-button-wrapper">
                    <input type="radio" className="radio-button" name="q5" checked={checkIfChecked('colorectal_screening_history', 2)} onChange={() => handleCalculatorChange('colorectal_screening_history', 2)} />
                    <div className="radio-button-text">Colonoscopy in the Last 10 Years, with Polyps</div>
                  </label>
                </div>
              </div>

              <div>
                <div className="question-wrapper">
                  <span className="question-number">6.</span>
                  <span className="question-text">How many servings of vegetables does the patient consume daily?</span>
                </div>

                <div className="answer-wrapper">
                  <label className="radio-button-wrapper">
                    <input type="radio" className="radio-button" name="q6" checked={checkIfChecked('veg_servings', 0)} onChange={() => handleCalculatorChange('veg_servings', 0)} />
                    <div className="radio-button-text">0 - 4 Servings</div>
                  </label>

                  <label className="radio-button-wrapper">
                    <input type="radio" className="radio-button" name="q6" checked={checkIfChecked('veg_servings', 1)} onChange={() => handleCalculatorChange('veg_servings', 1)} />
                    <div className="radio-button-text">5 or More Servings</div>
                  </label>
                </div>
              </div>

              <div>
                <div className="question-wrapper">
                  <span className="question-number">7.</span>
                  <span className="question-text">Does the patient regularly use NSAIDs (e.g., ibuprofen, aspirin)?</span>
                </div>

                <div className="answer-wrapper">
                  <label className="radio-button-wrapper">
                    <input type="radio" className="radio-button" name="q7" checked={checkIfChecked('nsaid_use', true)} onChange={() => handleCalculatorChange('nsaid_use', true)} />
                    <div className="radio-button-text">Non-User</div>
                  </label>

                  <label className="radio-button-wrapper">
                    <input type="radio" className="radio-button" name="q7" checked={checkIfChecked('nsaid_use', false)} onChange={() => handleCalculatorChange('nsaid_use', false)} />
                    <div className="radio-button-text">Regular User</div>
                  </label>
                </div>
              </div>

              <div>
                <div className="question-wrapper">
                  <span className="question-number">8.</span>
                  <span className="question-text">Has the patient used estrogen-based therapy within the last two years?</span>
                </div>

                <div className="answer-wrapper">
                  <label className="radio-button-wrapper">
                    <input type="radio" className="radio-button" name="q8" checked={checkIfChecked('estrogen', true)} onChange={() => handleCalculatorChange('estrogen', true)} />
                    <div className="radio-button-text">Negative</div>
                  </label>

                  <label className="radio-button-wrapper">
                    <input type="radio" className="radio-button" name="q8" checked={checkIfChecked('estrogen', false)} onChange={() => handleCalculatorChange('estrogen', false)} />
                    <div className="radio-button-text">Positive</div>
                  </label>
                </div>
              </div>

              <div>
                <div className="question-wrapper">
                  <span className="question-number">9.</span>
                  <span className="question-text">Does the patient experience symptoms such as abdominal pain, changes in bowel habits, or rectal bleeding?</span>
                </div>

                <div className="answer-wrapper">
                  <label className="radio-button-wrapper">
                    <input type="radio" className="radio-button" name="q9" checked={checkIfChecked('symptoms', true)} onChange={() => handleCalculatorChange('symptoms', true)} />
                    <div className="radio-button-text">Yes</div>
                  </label>

                  <label className="radio-button-wrapper">
                    <input type="radio" className="radio-button" name="q9" checked={checkIfChecked('symptoms', false)} onChange={() => handleCalculatorChange('symptoms', false)} />
                    <div className="radio-button-text">No</div>
                  </label>
                </div>
              </div>
            </div>
          </div>

          {loaded && (<ShowRisk risk={risk} scores={scores} calculatorData={calculatorData} patientData={selectedPatient} />)}
          {loaded && (<AskAi risk={risk} calculator={1} calculatorData={calculatorData} patientData={selectedPatient} />)}

          <Footer />
        </div>
      </div>
    </>
  );
};
