import React, { useEffect, useState } from "react";

import DashboardPatient from "./DashboardPatient";
import "./dashboard-patient-list-style.css";

function DashboardPatientList() {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [patients, setPatients] = useState<any>(null);

  useEffect(() => {
    if (patients === null && !loading) {
      fetchPatients();
    }
  }, []);

  const fetchPatients = async () => {
    setLoading(true);
    try {
      const response = await fetch("/api/patients");
      if (response.ok) {
        const data = await response.json();
        if (data.success === true) {
          setPatients(data.patients.length > 3 ? data.patients.slice(0, 3) : data.patients);
        } else {
          throw new Error(data.error);
        }
      } else {
        throw new Error(response.statusText);
      }
    } catch (error) {
      setError(true);
      setErrorMessage(error instanceof Error ? error.message : String(error));
    }
    setLoading(false);
  };

  return (
    <>
      <div className="patient-list">
        {error && <div>Error: {errorMessage}</div>}
        {patients &&
          patients.map((patient: any) => (
            <DashboardPatient key={patient.id} id={patient.id} name={patient.name} address={patient.address} />
        ))}
      </div>
    </>
  );
}

export default DashboardPatientList;
