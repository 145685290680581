import React from "react";
import { Link } from "react-router-dom";
import "./dashboard-after-style.css";

import ImageBrain from "../static/img/brain_study.webp";
import DividerLine from "../static/img/divider_line.svg";
import IconArrowLeft from "../static/img/icon_arrow_left.svg";
import IconArrowRight from "../static/img/icon_arrow_right.svg";

import { Menu } from "../Menu";
import { Header } from "../Header";
import { Footer } from "../Footer";
import DashboardPatientList from "./DashboardPatientList";

export const DashboardAfter = (): JSX.Element => {
  return (
    <>
      <Header />
      <div className="dashboard-after">
        <Menu />

        <div>
          <div className="top-panel">

            <div className="interactive-wrapper">
              <div className="interactive-text">Interactive Analytics Panel</div>
              <div className="key-patient-text">Display key patient analytics</div>
              <img width="425" height="296" alt="Brain study image" src={ImageBrain} />
            </div>

            <div className="upcoming-patient-wrapper">

              <div className="upcoming-patient-title-wrapper">
                <div className="upcoming-patient-title">Upcoming Patients</div>
              </div>

              <div className="upcoming-month-wrapper">
                <div className="upcoming-month">Dec 2024</div>
                <img width="16" height="16" alt="Arrow left icon" src={IconArrowLeft} />
                <img width="16" height="16" alt="Arrow right icon" src={IconArrowRight} />
              </div>

              <div className="calendar-day-wrapper calendar-day-wrapper-selected">
                <div className="calendar-day-frame calendar-day-frame-selected">
                  <div className="calendar-day-name">Mon</div>
                  <div className="calendar-day-number">2</div>
                </div>
                <div className="calendar-day-patient-wrapper">
                  <div className="calendar-day-patient-name">John Doe</div>
                  <div className="calendar-day-patient-time">10:00am - 10:30am</div>
                </div>
                <img width="16" height="16" alt="Arrow right icon" src={IconArrowRight} />
              </div>

              <div className="calendar-day-wrapper">
                <div className="calendar-day-frame">
                  <div className="calendar-day-name">Tues</div>
                  <div className="calendar-day-number">3</div>
                </div>
                <div className="calendar-day-patient-wrapper">
                  <div className="calendar-day-patient-name">Sophie Doe</div>
                  <div className="calendar-day-patient-time">11:00am - 11:30am</div>
                </div>
                <img width="16" height="16" alt="Arrow right icon" src={IconArrowRight} />
              </div>

            </div>

          </div>

          <div className="portal-header-wrapper">
            <div className="portal-header-title">Patient Portal</div>
          </div>

          <DashboardPatientList />

          <div className="assessments-container">

            <div className="assessments-header-wrapper">
              <div className="assessments-header-title">Health Assessments</div>
              <div className="assessments-view-all-wrapper">
                <Link to="/calculator" className="assessments-view-all">View All</Link>
                <img width="16" height="16" alt="Arrow right icon" src={IconArrowRight} />
              </div>
            </div>

            <div className="assessment-items">
              <div className="assessment-wrapper">
                <div className="assessment-title">Deep Vein Thrombosis Risk</div>
                <div className="assessment-info">High Cholesterol or Hypertension</div>
                <img width="333" height="1" className="assessment-divider" alt="Divider line" src={DividerLine} />
                <Link to="/calculator/3" className="primary-button assessment-btn">Calculate</Link>
              </div>

              <div className="assessment-wrapper">
                <div className="assessment-title">Colon Cancer Risk</div>
                <div className="assessment-info">Genetic Risk, Family History, and Personal Factors</div>
                <img width="333" height="1" className="assessment-divider" alt="Divider line" src={DividerLine} />
                <Link to="/calculator/1" className="primary-button assessment-btn">Calculate</Link>
              </div>

              <div className="assessment-wrapper">
                <div className="assessment-title">Chronic Kidney Disease Risk</div>
                <div className="assessment-info">Probability of Having Stage 3-5 CKD</div>
                <img width="333" height="1" className="assessment-divider" alt="Divider line" src={DividerLine} />
                <Link to="/calculator/2" className="primary-button assessment-btn">Calculate</Link>
              </div>
            </div>

          </div>

          <Footer />
        </div>
      </div>
    </>
  );
};
