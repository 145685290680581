import React from "react";

import BmiCircle from "../static/img/assessment/bmi_circle.svg";
import BmiColorBar from "../static/img/assessment/bmi_color_bar.svg";

import ShowRiskWidget from "./ShowRiskWidget";

const widgetTitles = {
  bmi: "BMI",
  colorectal_family_history: "Colorectal Family History",
  daily_cigs: "Daily Cigarettes",
  estrogen: "Estrogen",
  nsaid_use: "NSAID Use",
  sigmoidoscopy: "Sigmoidoscopy",
  veg_servings: "Vegetable Servings",
  weekly_exercise: "Weekly Exercise"
};

interface ShowRiskProps {
    risk: number;
    calculatorData: any;
    patientData: any;
    scores: any;
}

const ShowRisk: React.FC<ShowRiskProps> = ({ risk, scores, calculatorData, patientData }) => {
  const [riskScore, setRiskScore] = React.useState<number>(((risk - 1) * 100));
  const [riskLevel, setRiskLevel] = React.useState<string>(risk < 1.5 ? "low" : risk < 2.5 ? "moderate" : "high");

  return (
    <>
      <div className="form-title-wrapper">
        <div className="form-title">Calculator Results</div>
      </div>
      <div className="form-wrapper">
        <div className="widgets-wrapper">
          <div className="bmi-height-group">
              <div className="bmi-wrapper">
                <div className="bmi-title">Risk Score</div>
                <div className="bmi-values-wrapper">
                    <div className="bmi-value">{riskScore.toFixed(2)}%</div>
                    <div className={`bmi-status bmi-status-${riskLevel}`}>
                      {riskLevel}
                    </div>
                </div>
                <div className="bmi-bar-wrapper">
                    <img width="8" height="8" className={`bmi-circle-${riskLevel}`} alt="BMI circle" src={BmiCircle} />
                    <img width="239" height="12" alt="BMI bar" src={BmiColorBar} />
                </div>
              </div>
          </div>
        </div>
        <div className="widgets-wrapper widgets-wrapper-second-line">
          {Object.entries(scores).map(([key, value]) => (
            <ShowRiskWidget riskTitle={widgetTitles[key as keyof typeof widgetTitles]} riskValue={value as number} key={key} />
          ))}
        </div>
      </div>
    </>
  );
}

export default ShowRisk;
