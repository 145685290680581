import React from "react";
import { Navigate } from "react-router-dom";
import { useSessionUser } from "../SessionUserProvider";

type ProtectedRouteProps = {
  children: React.ReactNode;
};

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const { isLoading, sessionUser } = useSessionUser();

  if (isLoading) {
    return <></>;
  } else if (!sessionUser) {
    return <Navigate to="/login" />;
  }

  return (
    <>
      {children}
    </>
  );
};
