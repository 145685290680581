import React from "react";

import BmiCircle from "../static/img/assessment/bmi_circle.svg";
import BmiColorBar from "../static/img/assessment/bmi_color_bar.svg";

interface ShowDVTRiskProps {
    risk: number;
    calculatorData: any;
    patientData: any;
    scores: any;
}

const ShowDVTRisk: React.FC<ShowDVTRiskProps> = ({ risk, scores, calculatorData, patientData }) => {
  const [riskScore, setRiskScore] = React.useState<number>(risk);
  const [riskLevel, setRiskLevel] = React.useState<string>(risk <= 0 ? "low" : risk <= 2 ? "moderate" : "high");

  return (
    <>
      <div className="form-title-wrapper">
        <div className="form-title">Calculator Results</div>
      </div>
      <div className="form-wrapper">
        <div className="widgets-wrapper">
          <div className="bmi-height-group">
              <div className="bmi-wrapper">
                <div className="bmi-title">Risk Score</div>
                <div className="bmi-values-wrapper">
                    <div className="bmi-value">{riskScore}</div>
                    <div className={`bmi-status bmi-status-${riskLevel}`}>
                      {riskLevel}
                    </div>
                </div>
                <div className="bmi-bar-wrapper">
                    <img width="8" height="8" className={`bmi-circle-${riskLevel}`} alt="BMI circle" src={BmiCircle} />
                    <img width="239" height="12" alt="BMI bar" src={BmiColorBar} />
                </div>
              </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ShowDVTRisk;
