import React, { useEffect, useState } from "react";
import Select from 'react-select';

interface SelectPatientProps {
  setSelectedPatient: React.Dispatch<React.SetStateAction<string>>;
  setCalculatorData: React.Dispatch<React.SetStateAction<any>>;
  setFetchingPatient: React.Dispatch<React.SetStateAction<boolean>>;
}

const SelectPatient: React.FC<SelectPatientProps> = ({ setSelectedPatient, setCalculatorData, setFetchingPatient }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [patients, setPatients] = useState<any>(null);

  useEffect(() => {
    if (patients === null && !loading) {
      fetchPatients();
    }
  }, []);

  const fetchPatients = async () => {
    setLoading(true);
    try {
      const response = await fetch("/api/patients");
      if (response.ok) {
        const data = await response.json();
        if (data.success === true) {
          setPatients(data.patients.map((patient: any) => ({
            value: patient.id,
            label: patient.name
          })));
        } else {
          throw new Error(data.error);
        }
      } else {
        throw new Error(response.statusText);
      }
    } catch (error) {
      setError(true);
      setErrorMessage(error instanceof Error ? error.message : String(error));
    }
    setLoading(false);
  };

  const fetchPatient = async (id: number) => {
    setFetchingPatient(true);
    try {
      const response = await fetch(`/api/patients/${id}`);
      if (response.ok) {
        const data = await response.json();
        if (data.success === true) {
          setSelectedPatient(data.data);
          setCalculatorData(data.calculators_data);
        } else {
          throw new Error(data.error);
        }
      } else {
        throw new Error(response.statusText);
      }
    } catch (error) {
      setError(true);
      setErrorMessage(error instanceof Error ? error.message : String(error));
    }
    setFetchingPatient(false);
  };

  const handlePatientChange = (selectedOption: any) => {
    fetchPatient(selectedOption.value);
  };

  return (
    <>
      <div className="patient-name-wrapper">
          <div className="input-label">Patient:</div>
          {error && <div>Error: {errorMessage}</div>}
          {loading && <div>Loading...</div>}
          {!loading && patients && (
            <Select
              options={patients}
              aria-label="Patients"
              className="patient-input-border"
              onChange={handlePatientChange}
            />
          )}
      </div>
    </>
  );
}

export default SelectPatient;
