import React, { useState, useEffect } from "react";

import Avatar from "../static/img/avatar_big.webp";
import Edit from "../static/img/profile_edit.svg";

import "./style.css";

import { Menu } from "../Menu";
import { Header } from "../Header";
import { Footer } from "../Footer";
import { useSessionUser } from "../SessionUserProvider";
import { postJSON } from "../utils/utils";

export const UserProfileDetails = (): JSX.Element => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [role, setRole] = useState("");
  const [organization, setOrganization] = useState("");
  const [organizationLocation, setOrganizationLocation] = useState("");
  const { sessionUser, setSessionUser } = useSessionUser();
  const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false);
  const [errors, setErrors] = useState({
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    role: "",
    organization: "",
    organizationLocation: ""
  });

  useEffect(() => {
    handleReset();
  }, []);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSubmit();
    }
  };

  const handleSubmit = async () => {
    const data = {
      id: sessionUser?.id,
      firstName: firstName,
      lastName: lastName,
      email: email,
      phoneNumber: phoneNumber,
      role: role,
      organization: organization,
      organizationLocation: organizationLocation
    };

    setShowSuccessMessage(false);
    const response = await postJSON("/api/update-account", data);
    const errorsData: any = {
      id: "",
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      role: "",
      organization: "",
      organizationLocation: ""
    };

    if ("error" in response) {
      const items: any = response.error;
      for (const [key, value] of Object.entries(items)) {
        errorsData[key] = value;
      }
    } else {
      setSessionUser(response);
      setShowSuccessMessage(true);

      setFirstName(response.firstName);
      setLastName(response.lastName);
      setEmail(response.email);
      setPhoneNumber(response.phoneNumber);
      setRole(response.role);
      setOrganization(response.organization);
      setOrganizationLocation(response.organizationLocation);

      setTimeout(() => {
        setShowSuccessMessage(false);
      }, 3000);
    }

    setErrors(errorsData);
  }
  
  const handleReset = () => {
    const errorsData: any = {
      id: "",
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      role: "",
      organization: "",
      organizationLocation: ""
    };

    setShowSuccessMessage(false);
    setErrors(errorsData);

    if (sessionUser) {
      setFirstName(sessionUser.firstName);
      setLastName(sessionUser.lastName);
      setEmail(sessionUser.email);
      setPhoneNumber(sessionUser.phoneNumber);
      setRole(sessionUser.role);
      setOrganization(sessionUser.organization);
      setOrganizationLocation(sessionUser.organizationLocation);
    } else {
      setFirstName("");
      setLastName("");
      setEmail("");
      setPhoneNumber("");
      setRole("");
      setOrganization("");
      setOrganizationLocation("");
    }
  }

  return (
  <>
    <Header />
    <div className="dashboard-after">
      <Menu />
      <div>
      <div className="user-profile">

        <div className="profile-header-wrapper">
          <div className="profile-title">Profile</div>
          <img width="41" height="36" alt="Edit icon" src={Edit} />
          <button className="primary-button profile-btn" onClick={handleSubmit}>Save</button>
          <button className="secondary-button profile-btn" onClick={handleReset}>Reset</button>
        </div>

        <div className="profile-success">
          {showSuccessMessage ? "Your profile has been successfully updated" : "\u00A0"}
        </div>

        <div className="form-wrapper">
          <img width="118" height="147" alt="Avatar image" src={Avatar} />
          <div className="form-input-wrapper">
            <div className="user-info-wrapper">

              <div>
                <div className="input-label">First Name</div>
                <input
                  type="text"
                  className={`text-input ${errors.firstName ? 'error-border' : ''}`}
                  aria-label="First Name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  onKeyDown={handleKeyDown} />

                {errors.firstName &&
                  <div className="form-error">{errors.firstName}</div>
                }
              </div>
              
              <div>
                <div className="input-label">Last Name</div>
                <input
                  type="text"
                  className={`text-input ${errors.lastName ? 'error-border' : ''}`}
                  aria-label="Last Name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  onKeyDown={handleKeyDown} />

                {errors.lastName &&
                  <div className="form-error">{errors.lastName}</div>
                }
              </div>

              <div>
                <div className="input-label">Email</div>
                <input
                  type="text"
                  className={`text-input ${errors.email ? 'error-border' : ''}`}
                  aria-label="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  onKeyDown={handleKeyDown} />

                {errors.email &&
                  <div className="form-error">{errors.email}</div>
                }
              </div>

              <div>
                <div className="input-label">Phone Number</div>
                <input
                  type="text"
                  className={`text-input ${errors.phoneNumber ? 'error-border' : ''}`}
                  aria-label="Phone Number"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  onKeyDown={handleKeyDown} />

                {errors.phoneNumber &&
                  <div className="form-error">{errors.phoneNumber}</div>
                }
              </div>

            </div>

            <div className="user-role-wrapper">

              <div>
                <div className="input-label">Role</div>
                <input
                  type="text"
                  className="text-input"
                  aria-label="Role"
                  value={role}
                  onChange={(e) => setRole(e.target.value)}
                  onKeyDown={handleKeyDown} />
              </div>

              <div>
                <div className="input-label">Organization</div>
                <input
                  type="text"
                  className="text-input"
                  aria-label="Organization"
                  value={organization}
                  onChange={(e) => setOrganization(e.target.value)}
                  onKeyDown={handleKeyDown} />
              </div>

              <div>
                <div className="input-label">Organization Location</div>
                <input
                  type="text"
                  className="text-input"
                  aria-label="Organization Location"
                  value={organizationLocation}
                  onChange={(e) => setOrganizationLocation(e.target.value)}
                  onKeyDown={handleKeyDown} />
              </div>

            </div>
          </div>
        </div>

      </div>
      <Footer />
      </div>
    </div>
  </>
  );
};
