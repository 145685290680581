import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { LoginPage } from "./LoginPage";
import { LogoutPage } from "./LogoutPage";
import { DashboardAfter } from "./DashboardAfter";
import { CalendarView } from "./CalendarView";
import { UserProfileDetails } from "./UserProfileDetails";
import { SignUp } from "./SignUp";
import { CalculatorHomePage } from "./CalculatorHomePage";
import { AboutView } from "./AboutView";
import { ColorectalCancer } from "./AssessmentViews/ColorectalCancer";
import { ChronicKidney } from "./AssessmentViews/ChronicKidney";
import { DeepVeinThrombosis } from "./AssessmentViews/DeepVeinThrombosis";
import { SessionUserProvider } from "./SessionUserProvider";
import { ProtectedRoute } from "./ProtectedRoute";

function App() {
  return (
    <SessionUserProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/"            element={<ProtectedRoute> <DashboardAfter />     </ProtectedRoute>} />
          <Route path="calculator"   element={<ProtectedRoute> <CalculatorHomePage /> </ProtectedRoute>} />
          <Route path="calculator/1" element={<ProtectedRoute> <ColorectalCancer />   </ProtectedRoute>} />
          <Route path="calculator/2" element={<ProtectedRoute> <ChronicKidney />      </ProtectedRoute>} />
          <Route path="calculator/3" element={<ProtectedRoute> <DeepVeinThrombosis /> </ProtectedRoute>} />
          <Route path="calendar"     element={<ProtectedRoute> <CalendarView />       </ProtectedRoute>} />
          <Route path="about"        element={<ProtectedRoute> <AboutView />          </ProtectedRoute>} />
          <Route path="logout"       element={<ProtectedRoute> <LogoutPage />         </ProtectedRoute>} />
          <Route path="profile"      element={<ProtectedRoute> <UserProfileDetails /> </ProtectedRoute>} />
          <Route path="login"        element={<LoginPage />} />
          <Route path="signup"       element={<SignUp />} />
        </Routes>
      </BrowserRouter>
    </SessionUserProvider>
  );
}

export default App;
